import GetActivityTypeAsString from 'functions/GetActivityTypeAsString'
import ConvertToHoursAndMinutes from "components/ConvertToHoursAndMinutes"

// return the total length (in days, hours and/or minutes) of an activity or itinerary (provided via parameter).
const GetLengthInTime = (entity) => {
    switch (entity.__typename) {
        case ('node__itinerary'):
            return 'length: ' + GetActivityTypeAsString (entity.field_activity_length)
        case ('node__activity'):
            return 'length: ' + ConvertToHoursAndMinutes(entity.field_total_time_recommended)
        default:
            return 'Error (#4$234r)'
    }
}

export default GetLengthInTime;