import React from 'react';

import { CustomContainer } from "styles/CustomContainer.styles"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { LocationTypeMark } from "styles/GlobalStyles.js"

import LocationDetailsTable from "components/LocationDetailsTable"

import { Link } from "gatsby"

import FeatureImage from 'components/FeatureImage'

import LocationPageMap from 'components/CustomGoogleMaps/LocationPageMap'
import SaveButtonContainer from '../SavedItems/SaveButtonContainer/index.js';

// Render the details table for the activity content type (for both 'teaser' and 'detailed' modes.)
const LocationDetailed = ({ location }) => {
    console.log ('(#dks0) location: ', location)

    const featuredPhoto = location.relationships.field_locat_featured_photograph
    
    return (
        <SaveButtonContainer node={location}>
            <CustomContainer style={{width: "100%"}}>
                <Row>
                    <Col xs={12} md={12} lg={7}>
                        <FeatureImage featureImage={featuredPhoto} aspectRatio='1x1' />
                        <h5>Map location:</h5>
                        <LocationPageMap location={location} />
                    </Col>
                    <Col xs={12} md={12} lg={5}>
                        <LocationDetailsTable location={location} detailLevel={"teaser"} layout={"all_12s"} />
                    </Col>
                </Row>
            </CustomContainer>
        </SaveButtonContainer>
    )
};

export default LocationDetailed;