import React from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FeatureImage from 'components/FeatureImage'
import getFeaturedImage from 'functions/getFeaturedImage'

import { CustomContainer } from 'styles/CustomContainer.styles.js'

import { Link } from "gatsby"
import { ItineraryTypeMark, ActivityTypeMark } from "styles/GlobalStyles.js"

import GetLengthInTime from 'functions/GetLengthInTime'
import TypeMarker from '../TypeMarker';

// Render a list of "featured" itins/activities (and later OTA products)
// used for the location page
const FeaturedThings = ({ featuredList }) => {
    console.log ('featuredList (#cxz34): ', featuredList)
    if (featuredList.length < 1) {
        return 'No highlights provided (by contributor).'
    }
    
    return Object.entries(featuredList).map(([k, v]) => {
        const contributor = v.relationships.uid.display_name

        const featuredPhoto = getFeaturedImage(v)

        const leftColSize = 4
        const rightColSize = 12 - leftColSize

        return (
            <CustomContainer>
                <Row key={k}>
                    <Col xs={leftColSize} md={leftColSize} lg={leftColSize}>
                        <FeatureImage featureImage={featuredPhoto} aspectRatio='1x1' />
                    </Col>
                    <Col xs={rightColSize} md={rightColSize} lg={rightColSize}>
                        <TypeMarker node={v}></TypeMarker>
                        <div>
                            <Link to={v.fields.slug}>
                                {v.title}
                            </Link>
                            <br />{GetLengthInTime(v)}
                            <br />By: {contributor}
                        </div>
                    </Col>
                </Row>
            </CustomContainer>
        )
        
        // return {data.title}
    })
}

export default FeaturedThings;