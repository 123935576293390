import React, { useState } from "react"

import OurGoogleMap, { OurInfoWindow } from "components/OurGoogleMap"

import { withScriptjs, withGoogleMap } from "react-google-maps"

import { LocationMarker } from 'components/OurGoogleMap/GoogleMapHelpers.js'

// display <GoogleMap>, <Marker> (for photographs) and <InfoWindow> items)
// (note: the props is sent in via the prop on the "WrappedMap" component)
const CustomMap = ( {location} ) => {
console.log('(#44fw4feacwscd4). location: ', location)

  // when it's set, it will display an infoWindow panel on the map with custom information.
  const [infoWindowData, setinfoWindowData] = useState(null);

  // get map center and zoom
  const center = location.field_location_lng_lat

  // zoom level cheat sheet: http://bit.ly/3tZsNYM
  var mapZoom = Number.parseInt(location.field_map_zoom)
  if (!mapZoom) {
    // defaults to:
    mapZoom = 8
  }
  if (center) {
    return (
      <OurGoogleMap zoom={mapZoom} centerLat={center.lat} centerLng={center.lng}>
        
        <LocationMarker location={location} />
        <OurInfoWindow infoWindowData={infoWindowData} setinfoWindowData={setinfoWindowData} />
      </OurGoogleMap>
    )
  }
  
}

// determine if there is enough information to render a map
const isMapAvailable = (location) => {
  const center = location.field_location_lng_lat
  
  const isAvailable = (center) ? true : false
  console.log('isAvailable: (#jw8n34f)', isAvailable)

  return (center) ? true : false
  // return false
}

// the map you build is put in here, it links it to the Google Maps library code.
// this is also HOW you pass custom data into your map (via a parameter)
export const LocationPageMap = ( {location} ) => {
  var WrappedMap = withScriptjs(withGoogleMap(CustomMap))

  if (isMapAvailable(location)) {
    return (
      <div style={{width: '100%', height: '500px'}}>
                      
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawings,places&key=` + process.env.GATSBY_GOOGLE_MAPS_API_KEY + ``}
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
          location={location}
        />
      </div>
    )
  } else {
      // note: this will still render the map area, 
      return 'No map provided by contributor'
  }
}
export default LocationPageMap