import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"
import LocationDetailed from 'components/LocationDetailed'
// Todo:
// - generate page
// - write graphQL query
// - Write LocationDetailed component

const IndexPage = ({ data, pageContext }) => {

  console.log('(#45tgveun43z), data: ', data )
  
  const location = data.nodeLocation
  
  const title       = 'location' // todo:
  console.log('in Location page (#sdcd1). Location: ', location)

  return (
    <Layout>
      <SEO title={title} />
      <main>
        <LocationDetailed location={location}></LocationDetailed>
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query FindLocationByNId($nid: Int) {
  nodeLocation(drupal_internal__nid: {eq: $nid}) {
    id
    __typename
    drupal_id
    title
    drupal_internal__nid
    field_location_lng_lat {
      lat
      lng
      value
    }
    field_map_zoom
    relationships {

      field_state {
        id
        title
        drupal_internal__nid
        relationships {
          field_states_country {
            id
            title
            drupal_internal__nid
          }
        }
      }
      field_locat_featured_photograph {
        relationships {
          field_photo_images {
            localFile {
              uid
              childImageSharp {
                id
                fluid(
                  maxWidth: 600, maxHeight: 750, cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      field_location_highlights {
        ... on node__activity {
          id
          title
          fields {
            slug
          }
          field_total_time_recommended
          relationships {
            uid {
              drupal_id
              display_name
            }
            field_activity_feature_photo {
              relationships {
                field_photo_images {
                  localFile {
                    childImageSharp {
                      id
                      fluid(
                        maxWidth: 600, maxHeight: 750, cropFocus: CENTER
                      ) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on node__itinerary {
          id
          title
          field_activity_length
          drupal_internal__nid
          fields {
            slug
          }
          relationships {
            uid {
              drupal_id
              display_name
            }
            field_itin_feature_image {
              relationships {
                field_photo_images {
                  localFile {
                    childImageSharp {
                      id
                      fluid(
                        maxWidth: 600, maxHeight: 750, cropFocus: CENTER
                      ) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }


    }
  }
}
`