import React from 'react'
import { LocationDetails } from "./styles.js"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import FeaturedThings from 'components/FeaturedThings'

// render the details table for the location content type (for both 'teaser' and 'detailed' modes.)
const LocationDetailsTable = ({location, detailLevel, layout}) => {
    console.log('in LocationDetailsTable component (#conwe783). location = ', location)
    // var slug = location.fields.slug
     
    var left_lg, right_lg, left_md, right_md, left_sm, right_sm
    if (layout === 'teaser') {
        left_lg   = 5
        right_lg  = (12 - left_lg)
    
        left_md   = 4
        right_md  = (12 - left_lg)
    
        left_sm   = 12
        right_sm  = 12
    }

    if (layout === 'all_12s') {
        left_lg   = 12
        right_lg  = 12
    
        left_md   = 12
        right_md  = 12
    
        left_sm   = 12
        right_sm  = 12
    }
    
    if (layout === 'compact') {
        left_lg   = 3
        right_lg  = (12 - left_lg)
    
        left_md   = 3
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }

    const stateData     = location.relationships.field_state
    const stateName     = (stateData) ? stateData.title : null
    if (stateData) {
        const countryData   = stateData.relationships.field_states_country
        var countryName   = (countryData) ? countryData.title : "no country provided"
    }
    

    console.log('stateData: (#0sdnf)', stateData)
    
    var broadLocation
    if (!stateName) {
        broadLocation = 'not provided.'
    } else {
        broadLocation = stateName + ', ' + countryName
    }
    
    const data = {
        'Location': broadLocation
    }

    // extra 'detailLevel' conditional fields
    if (detailLevel !== 'teaser') {
        // data['not teaser data'] = 'hi'
    }
    
    const Details = ({ data }) => {
        console.log ('location detail table fields (#938xx): ', data)
        return Object.entries(data).map(([k, v]) => {
            if (!v) {
                return null
            }
            return (
                <Row key={k}>
                    <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        {k}:
                    </Col>
                    <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                        {v}
                    </Col>
                </Row>
        )});
    }

    // xxx - work from Here
    // - add activity / itinerary length
    // - add activity link
    // - add image for itinerary
    // - add image for activity

    const highlights = location.relationships.field_location_highlights
    console.log('highlights (#sdf0n24rf) ', highlights)
    
    // const rows = [{"cat": 'hello'}, {"cat": '2'}];
    // var headerText = 'asdg'
    return (
        <LocationDetails>
            {/* { headerText &&
                <Row>
                    <Col className="Right" xs={12} md={12} lg={12}>
                        <ActivityTypeMark>Activity</ActivityTypeMark>
                        <h2>{headerText}</h2>
                    </Col>
                </Row>
            } */}
            <Details data={data} />
            { detailLevel == 'teaser' &&
                <Row>
                    <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                        Things to do here:
                    </Col>
                    <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                        <FeaturedThings featuredList={highlights} />
                    </Col>
                </Row>
            }
        </LocationDetails>
    )
};

export default LocationDetailsTable;